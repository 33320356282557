import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Order} from './order';
import {Orderdetails} from './orderdetails';
import {Dlorderstatus} from './dlorderstatus';
import { Observable } from 'rxjs';
import {GlobalService} from '../../global.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http:HttpClient, private global: GlobalService) { }

  getStoreOrders(storeid):Observable<Order[]>{
    return this.http.get<Order[]>(this.global.getApiURL()+'api/Stores/GetStoreOrders/' +storeid);
  }
  getActiveDrivers(body):Observable<any>{
    return this.http.post<any>('https://automation.edinemenu.com/webapi/api/Employees/GetDriver', body);
  }
  getActiveVehicles(body):Observable<any>{
    return this.http.post<any>('https://automation.edinemenu.com/webapi/api/Assets/GetVehicle', body);
  }

  getOfficeOrders():Observable<Order[]>{
    return this.http.get<Order[]>(this.global.getApiURL()+'api/Stores/GetOfficeOrders');
  }

  getStorePreOrders(storeid):Observable<Order[]>{
    return this.http.get<Order[]>(this.global.getApiURL()+'api/Stores/GetStorePreOrders/' +storeid);
  }

  getOfficePreOrders():Observable<Order[]>{
    return this.http.get<Order[]>(this.global.getApiURL()+'api/Stores/GetOfficePreOrders');
  }

  getStoreOrdersDetails(orderid):Observable<Orderdetails[]>{
    return this.http.get<Orderdetails[]>(this.global.getApiURL()+'api/Stores/GetStoreOrderDetails/' +orderid);
  }

  getdlOrderStatus():Observable<Dlorderstatus[]>{
    return this.http.get<Dlorderstatus[]>(this.global.getApiURL()+'api/Stores/GetOrderStatus');
  }

  getStoreDrivers(storeId : number):Observable<any[]>{
    return this.http.get<any[]>(this.global.getApiURL()+'api/Users/GetStoreDrivers/' + storeId);
  }

  updateOrderStatus(data):Observable<Order[]>{
    return this.http.post<Order[]>(this.global.getApiURL()+ 'api/Orders/UpdateOrderStatus', data);
  }

  updateOfficeOrderStatus(data):Observable<Order[]>{
    return this.http.post<Order[]>(this.global.getApiURL()+ 'api/Orders/UpdateOfficeOrderStatus', data);
  }

  updatePreBookOrderStatus(data):Observable<Order[]>{
    return this.http.post<Order[]>(this.global.getApiURL()+ 'api/Orders/UpdatePreBookOrderStatus', data);
  }

  updatePreBookOfficeOrderStatus(data):Observable<Order[]>{
    return this.http.post<Order[]>(this.global.getApiURL()+ 'api/Orders/UpdatePreBookOfficeOrderStatus', data);
  }


  deleteOrder(id) {
    return this.http.post(this.global.getApiURL()+ 'api/Stores/DeleteOrder/' + id, id);
  }

  deleteOrderDetails(id) {
    return this.http.post(this.global.getApiURL()+ 'api/Stores/DeleteOrderDetails/' + id, id);
  }

  getUpcommingOrders(id){
    return this.http.get(this.global.getApiURL()+'api/Stores/GetStoreUpcomingPreOrders/'+id)
  }

  getOngoingOrders(id){
    return this.http.get(this.global.getApiURL()+'api/Stores/GetStoreOngoingPreOrders/'+id)
  }

  getOnholdOrders(id){
    return this.http.get(this.global.getApiURL()+'api/Stores/GetStoreOnHoldPreOrders/'+id)
  }

  updateBookingDetails(zone){
    return this.http.post(this.global.getApiURL()+'api/Orders/UpdateOrderCustomerDetails',zone)
  }

  orderDetailsByDate(zone){
    return this.http.post(this.global.getApiURL() +'api/Stores/GetStoreOrdersbyDate',zone)
  }

  assignDriver(data) : Observable<any> {
    return this.http.post(this.global.getApiURL() +'api/Orders/assignDriver',data)
  }

  changeSlot(zone){
    return this.http.post(this.global.getApiURL() +'api/Orders/ChangeBookingSlot',zone)
  }
}
