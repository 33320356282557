export class apiUrl {


    // region super admin reports
    public static readonly PlanStoreCountReport = 'CompanyReports/GetSubscriptionReport';
    public static readonly StoreSubscriptionReport = 'CompanyReports/GetStoreSubscriptionReport';
    public static readonly GetAllStoreLogChart = 'CompanyReports/GetAllStoreLogChart';
    public static readonly GetStoreLogChart = 'CompanyReports/GetStoreLogChart';


    public static readonly GetStoreVoidSales = 'StoreReports/GetStoreVoidSales';
    public static readonly GetStoreDiscountOrders = 'StoreReports/GetStoreDiscountOrders';


    public static readonly getPOSUserCheckoutReport = 'Users/getPOSUserCheckoutReport/';



    public static readonly GetStoreDetails = 'Stores/';



    // end

    public static readonly GetStorePlanDetails = 'StoreReports/GetStorePlanDetails';
    public static readonly GetStoreSubscriptionsHistory = 'StoreReports/GetStoreSubscriptionsHistory';
    public static readonly GetLogChartbyStore = 'CompanyReports/GetLogChartbyStore';
    public static readonly GetOneTimeChargesReport = 'CompanyReports/GetOneTimeChargesReport'
    public static readonly UnRegisteredCustomers = 'Customers/GetUnregCustomerinStore'


    public static readonly GetStoreHourlyOrderCount = 'StoreReports/GetStoreHourlyOrderCount';
    public static readonly GetStoreOrderCountbyRange = 'StoreReports/GetStoreOrderCountbyRange';




    public static readonly GetAllProducts = 'Products/GetProducts/';
    public static readonly GetProductById = 'Products/GetProduct/';
    public static readonly InsertProduct = 'Products/InsertProduct';
    public static readonly UpdateProduct = 'Products/UpdateProduct/';
    public static readonly DeleteProduct = 'Products/DeleteProduct/';


    public static readonly DeleteProductVariant = 'Products/DeleteProductVariant/';




    public static readonly GetdlStoreAddonMenu = 'Menu/GetdlStoreAddonMenu/';
    public static readonly GetdlCompanyAddonMenu = 'CompanyMenuCategories/GetdlCompanyAddonMenu/';


    public static readonly GetAllCompanyProducts = 'CompanyProducts/GetCompanyProducts/';
    public static readonly GetCompanyProductById = 'CompanyProducts/GetCompanyProduct/';
    public static readonly InsertCompanyProduct = 'CompanyProducts/InsertCompanyProduct';
    public static readonly UpdateCompanyProduct = 'CompanyProducts/UpdateCompanyProduct/';
    public static readonly DeleteCompanyProduct = 'CompanyProducts/DeleteCompanyProduct/';

    public static readonly DeleteCompanyProductVariant = 'CompanyProducts/DeleteCompanyProductVariant/';



    public static readonly GetAllStoreComboMenuCategories = 'ComboMenuCategories/GetStoreComboMenu/'
    public static readonly StoreComboMenuCategoriesDelete = 'ComboMenuCategories/DeleteComboMenu/'
    public static readonly SaveStoreComboMenuCategories = 'ComboMenuCategories'
    public static readonly GetStoreComboMenuCategoriesById = 'ComboMenuCategories/'
    public static readonly UpdateStoreComboMenuCategories = 'ComboMenuCategories/'


    public static readonly GetdlStoreMenuGroups = 'StoreMenuGroups/GetdlStoreMenuGroups/'
    public static readonly GetAllStoreStoreMenuGroups = 'StoreMenuGroups/GetStoreMenuGroups/'
    public static readonly StoreMenuGroupsDelete = 'StoreMenuGroups/DeleteStoreMenuGroup/'
    public static readonly SaveStoreStoreMenuGroups = 'StoreMenuGroups'
    public static readonly GetStoreStoreMenuGroupsById = 'StoreMenuGroups/'
    public static readonly UpdateStoreStoreMenuGroups = 'StoreMenuGroups/'


    public static readonly GetMenuGroupMappings = 'StoreMenuGroups/GetStoreMenuGroupMenuCategoryMapping'
    public static readonly InsertMenuGroupMappings = 'StoreMenuGroups/InsertStoreMenuGroupMenuCategoryMapping'




    public static readonly GetAllCompanyComboMenuCategories = 'CompanyComboMenuCategories/GetCompanyComboMenu/'
    public static readonly CompanyComboMenuCategoriesDelete = 'CompanyComboMenuCategories/DeleteCompanyComboMenu/'
    public static readonly SaveCompanyComboMenuCategories = 'CompanyComboMenuCategories'
    public static readonly GetCompanyComboMenuCategoriesById = 'CompanyComboMenuCategories/'
    public static readonly UpdateCompanyComboMenuCategories = 'CompanyComboMenuCategories/'



    //menu categores
    public static readonly GetAllCompanyMenuCategoriesForDropDown = 'CompanyMenuCategories/GetdlCompanyMenuCategories/'
    public static readonly GetAllStoreMenuCategoriesForDropDown = 'Menu/GetdlStoreMenu/'

//


    public static readonly GetAllStoreComboProducts = 'ComboProducts/GetStoreComboProduct/'
    public static readonly StoreComboProductsDelete = 'ComboProducts/DeleteComboProduct/'
    public static readonly DisableStoreComboProducts = 'ComboProducts/DisableComboProduct/'
    public static readonly SaveStoreComboProducts = 'ComboProducts'
    public static readonly GetStoreComboProductsById = 'ComboProducts/'
    public static readonly UpdateStoreComboProducts = 'ComboProducts/'
    public static readonly GetdlStoreComboProducts = 'ComboProducts/GetdlStoreComboProduct/'




    public static readonly GetActiveCompanyFeedbackQuestions = 'CompanyFeedback/GetActiveCompanyFeedbackQuestions/'
    public static readonly GetCompanyFeedbackQuestions = 'CompanyFeedback/GetCompanyFeedbackQuestions/'
    public static readonly GetCompanyFeedbackById = 'CompanyFeedback/'
    public static readonly DisableCompanyFeedbackQuestion = 'DisableCompanyFeedbackQuestion/'
    public static readonly SaveCompanyFeedback = 'CompanyFeedback'
    public static readonly UpdateCompanyFeedback = 'CompanyFeedback/'
    public static readonly DeleteCompanyFeedbackQuestion = 'CompanyFeedback/DeleteCompanyFeedbackQuestion/'
   
   
   
    public static readonly GetStoreEmployees = 'Employees/GetStoreEmployees/'
    public static readonly GetEmployeesById = 'Employees/GetEmployeebyId/'
    public static readonly DisableEmployee = 'Employees/DisableEmployee/'
    public static readonly SaveEmployees = 'Employees/InsertEmployee'
    public static readonly UpdateEmployees = 'Employees/UpdateEmployee/'
    public static readonly DeleteEmployee = 'Employees/DeleteEmployee/'
   
    public static readonly GetStoreCounters = 'StoreCounters/GetStoreCounters/'
    public static readonly GetStoreCountersById = 'StoreCounters/GetStoreCounter/'
    public static readonly DisableStoreCounter = 'StoreCounters/DisableStoreCounter/'
    public static readonly SaveStoreCounters = 'StoreCounters'
    public static readonly UpdateStoreCounters = 'StoreCounters/UpdateStoreCounter/'
    public static readonly DeleteStoreCounter = 'StoreCounters/DeleteStoreCounter/'


    public static readonly GetStoreFeedbackReport = 'StoreReports/GetStoreFeedbackReport'
    public static readonly GetStoreFeedbackChart = 'StoreReports/GetStoreFeedbackChart'


    public static readonly GetAllCompanyComboProducts = 'CompanyComboProducts/GetCompanyComboProduct/'
    public static readonly CompanyComboProductsDelete = 'CompanyComboProducts/DeleteCompanyComboProduct/'
    public static readonly DisableCompanyComboProducts = 'CompanyComboProducts/DisableCompanyComboProduct/'
    public static readonly SaveCompanyComboProducts = 'CompanyComboProducts'
    public static readonly GetCompanyComboProductsById = 'CompanyComboProducts/'
    public static readonly UpdateCompanyComboProducts = 'CompanyComboProducts/'
    public static readonly GetdlCompanyComboProducts = 'CompanyComboProducts/GetdlCompanyComboProduct/'


    public static readonly GetAllStoreAddOns = 'Products/GetStoreAddOns/'
    public static readonly DeleteStoreAddOns = 'Products/DeleteAddOn/'
    public static readonly SaveStoreAddOns = 'Products/InsertAddOn'
    public static readonly GetStoreAddOnsById = 'Products/GetAddon/'
    public static readonly UpdateStoreAddOns = 'Products/UpdateAddOn/'
    public static readonly DeleteStoreAddOnMapping = 'Products/DeleteAddonMapping/'
    public static readonly DeleteStoreComboProductMapping = 'Products/DeleteComboProductMapping/'



    public static readonly GetAllCompanyGroupsdl = 'CompanyBrands/GetdlCompanyBrands';
    public static readonly GetAllCompanyGroups = 'CompanyBrands';
    public static readonly SaveCompanyGroup = 'CompanyBrands';
    public static readonly UpdateCompanyGroup = 'CompanyBrands/';
    public static readonly GetCompanyGroupbyId = 'CompanyBrands/';
    public static readonly DeleteCompanyGroup = 'CompanyBrands/DeleteCompanyBrand/';
    public static readonly DisableCompanyGroup = 'CompanyBrands/DisableCompanyBrand/';





    public static readonly GetAllLoyaltys = 'Loyalties';
    public static readonly GetCompanyLoyalties = 'Loyalties/GetCompanyLoyalties';


    public static readonly GetLoyaltyById = 'Loyalties/';
    public static readonly UpdateLoyality = 'Loyalties/';
    public static readonly SaveLoyalty = 'Loyalties';
    public static readonly DisableLoyalty = 'Loyalties/DisableLoyalty/';
    public static readonly DeleteLoyalty = 'Loyalties/DeleteLoyalty/';

    public static readonly GetdlLoyalty = 'Loyalties/GetdlLoyalty/';



    public static readonly GetCompanyLoyaltyMenuGroups = 'LoyaltyMenuGroups/GetCompanyLoyaltyMenuGroups/';
    public static readonly GetLoyaltyMenuGroupsById = 'LoyaltyMenuGroups/';
    public static readonly UpdateLoyalityMenuGroups = 'LoyaltyMenuGroups/';
    public static readonly SaveLoyaltyMenuGroups = 'LoyaltyMenuGroups';
    public static readonly DeleteLoyaltyMenuGroups = 'LoyaltyMenuGroups/DeleteLoyaltyMenuGroup/';





    public static readonly GetCompanyLoyaltyThirdParties = 'LoyaltyThirdParties/GetCompanyThirdPartyLoyalties/';
    public static readonly GetLoyaltyThirdPartiesById = 'LoyaltyThirdParties/';
    public static readonly UpdateLoyaltyThirdParties = 'LoyaltyThirdParties/';
    public static readonly SaveLoyaltyThirdParties = 'LoyaltyThirdParties';
    public static readonly DeleteLoyaltyThirdParties = 'LoyaltyThirdParties/DeleteThirdPartyLoyalty/';
    public static readonly DisableThirdPartyLoyalty = 'LoyaltyThirdParties/DisableThirdPartyLoyalty/';




    public static readonly GetCateringMenuCategories = 'CateringMenuCategories/GetStoreCateringMenuCategories/';
    public static readonly GetCateringMenuCategoriesById = 'CateringMenuCategories/';
    public static readonly UpdateCateringMenuCategories = 'CateringMenuCategories/';
    public static readonly SaveCateringMenuCategories = 'CateringMenuCategories';
    public static readonly DeleteCateringMenuCategories = 'CateringMenuCategories/DeleteCateringMenuCategory/';
    public static readonly DisableCateringMenuCategories = 'CateringMenuCategories/DisableCateringMenuCategory/';
    public static readonly GetdlCateringMenuCategories = 'CateringMenuCategories/GetdlCateringMenuCategories/';


    public static readonly GetCateringProducts = 'CateringProducts/GetStoreCateringProducts/';
    public static readonly GetCateringProductsById = 'CateringProducts/';
    public static readonly UpdateCateringProducts = 'CateringProducts/';
    public static readonly SaveCateringProducts = 'CateringProducts';
    public static readonly DeleteCateringProducts = 'CateringProducts/DeleteCateringProduct/';
    public static readonly DisableCateringProducts = 'CateringProducts/DisableCateringProduct/';
    public static readonly GetdlCateringProducts = 'CateringProducts/GetdlCateringProducts/';






    public static readonly GetLoyaltyCompanyMenuMapping = 'LoyaltyMenuGroups/GetLoyaltyCompanyMenuMapping/';

    public static readonly LoyaltyMenuGroupMappingInsert = 'LoyaltyMenuGroups/LoyaltyMenuGroupMappingInsert';



    public static readonly GetMenuCategorySalesReport = 'StoreReports/GetMenuCategorySalesReport';




    public static readonly GetAllCOmpaniesForDropDown = 'Company/GetdlCompanies';


    public static readonly GetAllCompanyBrandsForDropDown = 'CompanyBrands/GetdlCompanyBrands';







    public static readonly GetAllCompanyAddOns = 'CompanyProducts/GetCompanyAddOns/'
    public static readonly DeleteCompanyAddOns = 'CompanyProducts/DeleteCompanyAddOn/'
    public static readonly SaveCompanyAddOns = 'CompanyProducts/InsertCompanyAddOn'
    public static readonly GetCompanyAddOnsById = 'CompanyProducts/GetCompanyAddon/'
    public static readonly UpdateCompanyAddOns = 'CompanyProducts/UpdateCompanyAddOn/'


    public static readonly DeleteCompanyAddOnMapping = 'CompanyProducts/DeleteCompanyAddonMapping/'
    public static readonly DeleteCompanyComboProductMapping = 'CompanyProducts/DeleteCompanyComboProductMapping/'




    public static readonly GetBulkSMSLog = 'Customers/GetBulkSMSLog'


    public static readonly GetCustomerLoyaltyReport = 'CompanyReports/GetCustomerLoyaltyReport'
    public static readonly GetCustomerLoyalty = 'Customers/GetCustomerLoyalty'
    public static readonly GetLoyaltyPointsReport = 'CompanyReports/GetCustomerLoyaltyPointsReport'
    public static readonly GetLoyaltyPointsLogReport= 'CompanyReports/GetCustomerLoyaltyPointsLogReport'



    public static readonly GetdlCompaniesbyBrandId= 'Company/GetdlCompaniesbyBrandId/'



    public static readonly GetCateringOrderReport= 'StoreReports/GetCateringOrderReport'
    public static readonly GetCateringPaymentReport ='StoreReports/GetCateringPaymentReport';

    public static readonly GetStoreEmployeesLogReport ='StoreReports/GetStoreEmployeesLogReport';
    public static readonly GetStoreSalesbyCategoryReport ='StoreReports/GetStoreSalesbyCategoryReport';


  //product-points
    public static readonly GetProductPointsMapping='Products/GetProductPointsMapping/'
    public static readonly SaveProductPointsMapping='Products/GetProductPointsMappingInsert'
  //product points report
    public static readonly GetWaiterPointsReport ='StoreReports/GetWaiterPointsReport'
    public static readonly GetWaiterPointsDetailedReport ='StoreReports/GetWaiterPointsDetailedReport'
  }
