<router-outlet>
    <app-spinner></app-spinner>
</router-outlet>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

                        <!-- <qrcode [qrdata]="'Your data'" class="qr-code__image" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode> -->
