
<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block d-lg-none">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>

    </li>
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <li>

        <h4 class="page-title" style="margin-left:15px; margin-top: 20px; font-size:30px; background-color: none;">


      
         <ng-container *ngIf="userDetails?.roleId == 3"> {{userDetails?.storeName}}</ng-container>
         <ng-container *ngIf="userDetails?.roleId == 2"> {{userDetails?.companyName}}</ng-container>
         <ng-container *ngIf="userDetails?.roleId == 11"> {{userDetails?.companyBrandName}}</ng-container>

        
        
        </h4>
           </li>

           <li  *ngIf="roleId == 1">
            <a href="https://kitchenvision.edinemenu.com/" target="_blank">
            <h4 class="page-title" style="margin-left:200px; margin-top:10px; border-radius: 8px; font-size: 15px; font-weight: normal; color: #fff; height: 40px;padding: 10px 15px; background-color:#ff2b00;">
    
         Kitchen Vision</h4></a>
               </li>



               <li *ngIf="roleId == 1">
                <a href="https://automation.edinemenu.com/" target="_blank">
                <h4 class="page-title" style="margin-left:15px; margin-top:10px; border-radius: 8px; font-size: 15px; font-weight: normal; color: #fff; height: 40px;padding: 10px 15px; background-color:#ff2b00;">
        
               eDine Automation</h4></a>
                   </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->

    <li>
    <h4 class="page-title" style="float: right; margin-top: 20px; font-size:21px; background-color: none;">Welcome, {{userName}}</h4>
       </li>






    <li class="nav-item">
        <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="#">

            <img src="assets/images/notification.png" alt="user" class="rounded-circle" width="31">
        </a></li>



    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/menu-top.png" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu user-dd" ngbDropdownMenu>
           <!-- <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a> -->
            <div class="dropdown-divider"></div>
            <a *ngIf="roleId == 3" class="dropdown-item" routerLink="/master-settings">
                <i class="ti-settings m-r-5 m-l-5"></i> Setting</a>
            <div class="dropdown-divider"></div>
            <a *ngIf="roleId == 3" class="dropdown-item" routerLink="/account">
                <i class="ti-settings m-r-5 m-l-5"></i> Account</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <!-- <div class="dropdown-divider"></div> -->
            <!-- <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div> -->
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
