import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorsRoutes } from './error.routing';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';



@NgModule({
  declarations: [AccessdeniedComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ErrorsRoutes)
  ]
})
export class ErrorModule { }
