import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/auth/login/user';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  holdSalesInfo : Array<any> = [];
  comboStatus : string;
  addonStatus : string;
  selectedProduct : any;
  selectedComboPro : any;
  selectedAddonPro: any;


  allorders: any = [];
  

  constructor(
    private modalService : NgbModal,

  ) { }


  setHoldSales(sales){
    this.holdSalesInfo.push(sales);
  }

  setComboStatus(status){
    this.comboStatus = status;
  }

  setAddonStatus(status){
    this.addonStatus = status;
  }

  setSelectedProduct(data){
    this.selectedProduct = data;
  }

  shareComboPro(data){
    this.selectedComboPro = data;
  }

  shareAddonPro(data){
    this.selectedAddonPro = data;
  }



  private productStatus = new BehaviorSubject<any>("");
  ProductStatus = this.productStatus.asObservable();

  sendProductStatus(data){
    this.productStatus.next(data);
  }

  public storeDetails: BehaviorSubject<any> = new BehaviorSubject<any>({});



  private pushNotification = new BehaviorSubject<any>("");
  PushNotification = this.pushNotification.asObservable();

  setPushNotification(data){
    this.pushNotification.next(data);
  }

  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}

  checkExpiredRegularDaysOrNot(){
    let user : User = JSON.parse(localStorage.getItem('currentUser'));
    if(user.expiryDate == null) return;
    let dateEnd ;
    dateEnd = (this.datediff(new Date , new Date(user.expiryDate)));
    // dateEnd = dateEnd + user.extendedDays;
    // user.reqularWarningBefore = 90;
    // if(dateEnd <= user.reqularWarningBefore){
    //   const dialogRef = this.modalService.open(SubscriptionWarningPopupComponent, {size : 'xl', centered : true});
    //   dialogRef.componentInstance.days = dateEnd;
    //   dialogRef.result.then(res =>{
    //   });
    // }
    // else{
    //   return false;
    // }

  }
  checkPlanExpired(){
    let user : User = JSON.parse(localStorage.getItem('currentUser'));
    if(user.expiryDate == null) return;
    let dateEnd ;
    dateEnd = (this.datediff(new Date , new Date(user.expiryDate)));
    dateEnd = dateEnd + user.extendedDays;
    // user.reqularWarningBefore = 90;
    if(dateEnd <= 0){
      return true;
    }
    else{
      return false;
    }

  }

}
