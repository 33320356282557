import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DbService } from '../services/db.service';
import { apiUrl } from '../constants/apiUrlConstants';
import { SharedDataService } from '../services/shared-data.service';
//declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  storeName: any;
  userName: any;
  userDetails : any;
  roleId: any;
  storeId : number = 0;

  constructor(private toastr: ToastrService, private router:Router,
    private sharedData : SharedDataService,
    private dbService : DbService) {

    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.userDetails = user;
    this.storeName = user.storeName;
    this.storeId = user.storeId;

    this.userName = user.username;
    this.roleId = user.roleId;
    this.getStoreDetails();
  }

  onLogout(){
    localStorage.removeItem('currentUser');
    localStorage.removeItem('orderCount');
    localStorage.clear();
    this.toastr.clear();
    this.router.navigate(['/auth']);

  }


  getStoreDetails(){
    this.dbService.methodGet(apiUrl.GetStoreDetails +this.storeId).subscribe(resdata =>{
      this.sharedData.storeDetails.next(resdata);
      // this.storeDetails = resdata;
    })
}
}
