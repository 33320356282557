import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './auth/login/login.component';
import { AccessdeniedComponent } from './error/accessdenied/accessdenied.component';
import { LoginserviceService } from './auth/login/loginservice.service';
import { ActivateGuard } from './admin/activate.guard';

export const Approutes: Routes = [
  {
    path: '', component: LoginComponent
  },
  {
    path: '',
    component: LoginComponent,
    children: [
      { path: '', redirectTo: '/auth', pathMatch: 'full' },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate:[ActivateGuard]
      },
      {
        path: 'feed-back-form',
        loadChildren: () => import('./views/feedback-form/feedback-form.module').then(m => m.FeedbackFormModule), canActivate:[ActivateGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule), canActivate:[ActivateGuard]
      },
      {
        path: 'inventory',
        loadChildren: () => import('./views/inventory/inventory.module').then(m => m.InventoryModule), canActivate:[ActivateGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule), canActivate:[ActivateGuard]
      },
      {
        path: 'rating',
        loadChildren: () => import('./views/rating/rating.module').then(m => m.RatingModule), canActivate:[ActivateGuard]
      },
      {
        path: 'organization',
        loadChildren: () => import('./views/organization/organization.module').then(m => m.OrganizationModule), canActivate:[ActivateGuard]
      },
      {
        path: 'assets',
        loadChildren: () => import('./views/assets/assets.module').then(m => m.AssetsModule), canActivate:[ActivateGuard]
      },
      {
        path: 'menu',
        loadChildren: () => import('./views/menu/menu.module').then(m => m.MenuModule), canActivate:[ActivateGuard]
      },
      {
        path: 'send-sms',
        loadChildren: () => import('./views/send-sms/send-sms.module').then(m => m.SendSmsModule), canActivate:[ActivateGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[ActivateGuard]
      },
      {
        path: 'master-settings',
        loadChildren: () => import('./views/master-settings/master-settings.module').then(m => m.MasterSettingsModule), canActivate:[ActivateGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule), canActivate:[ActivateGuard]
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule), canActivate:[ActivateGuard]
      },
      {
        path: 'counters',
        loadChildren: () => import('./views/counters/counters.module').then(m => m.CountersModule), canActivate:[ActivateGuard]
      },
      {
        path: 'loyalty',
        loadChildren: () => import('./views/loyalty/loyalty.module').then(m => m.LoyaltyModule), canActivate:[ActivateGuard]
      },
      {
        path: 'catering',
        loadChildren: () => import('./views/catering/catering.module').then(m => m.CateringModule), canActivate:[ActivateGuard]
      },
      {
        path: 'side-bar-view',
        loadChildren: () => import('./views/side-menu-view/side-menu-view.module').then(m => m.SideMenuViewModule), canActivate:[ActivateGuard]
      },
      {
        path: 'product-points',
        loadChildren: () => import('./views/product-points/product-points.module').then(m => m.ProductPointsModule), canActivate:[ActivateGuard]
      },
      // {
      //   path: 'sales-reports',
      //   loadChildren: () => import('./views/side-menu-view/side-bar-reports-view/side-bar-reports-view.module').then(m => m.SideBarReportsViewModule), canActivate:[ActivateGuard]
      // },
      //  {
      //    path: 'settings-view',
      //    loadChildren: () => import('./views/side-menu-view/side-bar-settings-view/side-bar-settings-view.module').then(m => m.SideBarSettingsViewModule), canActivate:[ActivateGuard]
      //  },
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [

    ]
  },
  // {
  //   path: '',
  //   component: FullComponent,
  //   children: [
  //     {
  //       path: 'waiter-pos',
  //       loadChildren: () => import('./waiter-pos/waiter-pos.module').then(m => m.WaiterPosModule), canActivate:[ActivateGuard]
  //     }
  //   ]
  // },
  {
    path: '',
    component: AccessdeniedComponent,
    children: [
      { path: '', redirectTo: '/error', pathMatch: 'full' },
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
      },
      {
        path: 'accessdenied',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];



