import { RouteInfo } from './sidebar.metadata';

export const COMPANYROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-chart-pie',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/company-dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-view-dashboard',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/store-dashboard',
        title: 'Store Dashboard',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/feed-back-form/questions',
        title: 'Feed Back',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  {
    path: '',
    title: 'Organization',
    icon: 'mdi mdi-home',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/organization/store',
        title: 'Store',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Loyalty',
    icon: 'mdi mdi-home',
    class: 'nav-small',
    extralink: false,
    submenu: [
      // {
      //   path: '/loyalty/',
      //   title: 'Loyalty',
      //   icon: 'mdi mdi-food-fork-drink',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/loyalty/loyalty-menu-group',
      //   title: 'Loyalty Menu Group',
      //   icon: 'mdi mdi-food-fork-drink',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/loyalty/loyalty-type',
        title: 'Loyalty Type',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/reports/loyalty-reports',
      //   title: 'Loyalty Report',
      //   icon: 'mdi mdi-food-fork-drink',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/reports/loyalty-reports/customer-loyalty-report',
      //   title: 'Customer Loyalty Report',
      //   icon: 'mdi mdi-food-fork-drink',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/reports/loyalty-reports/loyalty-points-report',
        title: 'Loyalty Points Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/loyalty-points-log-report',
        title: 'Loyalty Points Log Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  {
    path: '',
    title: 'Menu',
    icon: 'mdi mdi-menu',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/menu',
        title: 'Menu',
        icon: 'mdi mdi-file-document-box',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/menu/products',
        title: 'Products',
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/menu/menu-combo',
      //   title: 'Menu Combo',
      //   icon: 'mdi mdi-file-document-box',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/menu/combo-menu-categories',
        title: 'Combo Categories',
        icon: 'mdi mdi-file-document-box',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/menu/company-menu-to-store',
        title: 'Menu Move To Store',
        icon: 'mdi mdi-file-document-box',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/menu/product',
      //   title: 'Product',
      //   icon: 'mdi mdi-silverware-variant',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },

      // {
      //   path: '/menu/product-combo',
      //   title: 'Product Combo',
      //   icon: 'mdi mdi-silverware-variant',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/menu/combo-products',
        title: 'Combo Products',
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/menu/addon',
      //   title: 'Addon Product',
      //   icon: 'mdi mdi-silverware-variant',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/menu/add-ons',
        title: 'Addon Products',
        params : {url : true},
        icon: 'mdi mdi-silverware-variant',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },


  {
    path: '',
    title: 'Assets',
    icon: 'mdi mdi-food',
    class: 'nav-small',
    extralink: false,
    submenu: [
      // {
      //   path: '/admin/table',
      //   title: 'Table',
      //   icon: 'mdi mdi-sofa',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/assets/employee',
        title: 'Employee',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },



  {
    path: '',
    title: 'Reports',
    icon: 'mdi mdi-file-document-box',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/reports/store-reports/orderreport',
        title: 'Order History',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/subscribed-customers-report',
        title: 'Subscribed Customers',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/tablereport',
        title: 'Table History',
        icon: 'mdi mdi-chart-gantt',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/salesreport',
        title: 'Sales Report By Product',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-report-by-category',
        title: 'Sales Report By Category',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-report-by-date',
        title: 'Sales Report By Date',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/complementary-report',
        title: 'Complementary Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/cancel-report',
        title: 'Cancelled Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/void-sales-report',
        title: 'Void Sales Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/discount-report',
        title: 'Discount Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/expense-report',
        title: 'Expense Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/feed-back-form-report',
        title: 'Feedback Form Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/reports/store-reports/salesman-wise-sales-report',
        title: 'Sales Report by Salesman',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-summary-consolidated-report',
        title: 'Daily Sales Summary',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-checkin-summary-consolidated-report',
        title: 'Sales Summary by Checkin',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/sales-summary-consolidated-report-by-date',
        title: 'Sales Summary Consolidated',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/advanced-sales-summary-consolidated-report-by-date',
        title: 'Advanced Sales Summary',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/driver-assign-report',
        title: 'Driver Assign Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/checkin-checkout-report',
        title: 'Checkin Checkout Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/driver',
        title: 'Driver Report',
        icon: 'mdi mdi-chart-areaspline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/customer',
        title: 'Customer',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/admin-reports/log-reports/store-log-report',
        title: 'Store Log Report',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/customer-credit-report',
        title: 'Credit Report',
        icon: 'mdi mdi-history',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/send-sms/send-sms-log',
        title: 'SMS Log Report',
        icon: 'mdi mdi-book',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/catering-report',
        title: 'Catering Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/store-reports/waiter-points-report',
        title: 'Waiter Points Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },


    ]
  },

];
