import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../auth/login/user';
import { COMPANYROUTES } from '../shared/sidebar/company-menu-items';
import { ROUTES } from '../shared/sidebar/menu-items';
import { OFFICEROUTES } from '../shared/sidebar/office-menu-items';
import { STAFFROUTES } from '../shared/sidebar/staff-menu-items';
import { STOREROUTES } from '../shared/sidebar/store-menu-items';
import { ToastrService } from 'ngx-toastr';
import {Constants } from '../shared/sidebar/constants';
import { DbService } from '../shared/services/db.service';
import { CASHERROUTES } from '../shared/sidebar/casher-menu-items';
import { WAITERROUTES } from '../shared/sidebar/waiter-menu-items';
import { SharedDataService } from '../shared/services/shared-data.service';
import { BRANDROUTES } from '../shared/sidebar/brand-menu-items';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {
  sidebarnavItems ;
  constructor(private router:Router, private toastr: ToastrService , private dbService : DbService,
    private data : SharedDataService,
    ){
  }
  canActivate(

    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var succ = JSON.parse(localStorage.getItem('currentUser'));

    //return true;
    if(succ)
    {
      //
      if( this.userRoleCheck(state.url) ==  true){
        if(state.url.includes('/booking-info') || state.url.includes('/pos-system')){
         this.dbService.defaultSidebar ='mini-sidebar'
        }
        else{
         this.dbService.defaultSidebar ='full'
        }
        if(succ.expired == false){
          return true;
        }
        else {
          if(state.url.includes('/account')){
          return true;
        }
        else{
          return false;
        }
      }
    }

      else{
        this.toastr.warning('Access Denied !!!');
        return false;
      }
      // return true;
    }
    else{
      //alert("Access Denied, Redirecting to Login Page");
      this.router.navigate(['/error']);
      return false;
    }
  }


  userRoleCheck(url){
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if(user.roleId == 1)
    {
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(user.roleId == 2)
    {
      this.sidebarnavItems = COMPANYROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(user.roleId == 3)
    {
      this.sidebarnavItems = STOREROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(user.roleId == 4)
    {
      this.sidebarnavItems = STAFFROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(user.roleId == 5)
    {
      this.sidebarnavItems = OFFICEROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(user.roleId == 6)
    {
      this.sidebarnavItems = WAITERROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(user.roleId == 8)
    {
      this.sidebarnavItems = CASHERROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    else if(user.roleId == 11)
    {
      this.sidebarnavItems = BRANDROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    let isAllowedMappedMenu : boolean = false;



    if(user.roleId == 1){
     if(Constants.roleIdMenu1.find(x => url.includes(x.path))){
       isAllowedMappedMenu = true;
     }
    }
    if(user.roleId == 2){
     if(Constants.roleIdMenu2.find(x => x.path === url)){
       isAllowedMappedMenu = true;
     }
    }
    if(user.roleId == 3){
     if(Constants.roleIdMenu3.find(x => x.path === url)){
       isAllowedMappedMenu = true;
     }
    }
    if(user.roleId == 4){
     if(Constants.roleIdMenu4.find(x => x.path === url)){
       isAllowedMappedMenu = true;
     }
    }
    if(user.roleId == 5){
     if(Constants.roleIdMenu5.find(x => x.path === url)){
       isAllowedMappedMenu = true;
     }
    }
    if(user.roleId == 6){
      if(Constants.roleIdMenu6.find(x => x.path === url)){
        isAllowedMappedMenu = true;
      }
    }
    if(user.roleId == 8){
      if(Constants.roleIdMenu8.find(x => x.path === url)){
        isAllowedMappedMenu = true;
      }
     }
     if(user.roleId == 11){
      if(Constants.roleIdMenu11.find(x => x.path === url)){
        isAllowedMappedMenu = true;
      }
     }

    if(this.sidebarnavItems.find(x => x.path === url) || isAllowedMappedMenu){
      return true;
    }
    else {
      return false;
    }

    // this.sidebarnavItems.find(x => x.path ==)

  }

}
