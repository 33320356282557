<div class="d-flex align-items-center text-center error-page bg-primary pt-5 pb-4 h-100">
    <div class="row flex-grow">
      <div class="col-lg-8 mx-auto text-white">
        <div class="row align-items-center d-flex flex-row">
          <div class="col-lg-6 text-lg-right pr-lg-4">
            <h1 class="display-1 mb-0">401</h1>
          </div>
          <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
            <h2>SORRY!</h2>
            <h3 class="font-weight-light">You dont have permission to view this Page.</h3>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center mt-xl-2">
            <a class="text-white font-weight-medium" routerLink="/auth">Back to Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
