import { Injectable, OnInit } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { HubConnection } from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/app/global.service';
import { id } from '@swimlane/ngx-charts';
import { LoginserviceService } from 'src/app/auth/login/loginservice.service';
import {SharedDataService} from './shared-data.service';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  apiCall : any;

  private hubConnection : HubConnection;
  constructor(private http:HttpClient, 
              private global: GlobalService,
              private service : LoginserviceService,
              private data : SharedDataService) { }

              startConnection() {
                //signalR 
                // console.log(this.global.getApiURL());
                this.hubConnection = new signalR.HubConnectionBuilder()
                  .withUrl(this.global.getApiURL()+'pushNotification')
                  .configureLogging(signalR.LogLevel.Information)
                  .build();
            
            
                this.hubConnection.start().then(() => {
                  // console.log('connection started');
                }).catch(err => console.log('connection started error', err));
            
                this.hubConnection.onclose(() => {
            
                  setTimeout(() => {
                    // console.log('try to re start connection');
                    this.hubConnection.start().then(() => {
                      // console.log('connection re started');
                    }).catch(err => console.log('connection re started error', err));
                  }, 5000);
            
                });
            
                this.hubConnection.on('privateMessageMethodName', (data : any) => {
                  let notify = data;
                  this.apiCall = notify.orderType;
                    // console.log('private msg',this.apiCall)
                  this.data.setPushNotification(this.apiCall);
                });

                this.hubConnection.on('CustomMessage', (data : any) => {
                  let notify = data;
                  this.apiCall = notify.orderType;
                  // console.log('CustomMessage msg',this.apiCall)
                  this.data.setPushNotification(this.apiCall);
                });
            
                this.hubConnection.on('publicMessageMethodName', (data) => {
                  // console.log('public Message:' + data);
                });
            
                this.hubConnection.on('clientMethodName', (data) => {
                  // console.log('server message:' + data);
                });
            
                this.hubConnection.on('WelcomeMethodName', (data) => {
                  // console.log('client Id:' + data);
            
                  //connnection info pass to server
                  let user = JSON.parse(localStorage.getItem('currentUser'));
                  let info = {
                    Id:  user.id,
                    ConnectionId : data
                    }
                    this.service.userConnection(user.id,info).subscribe(res =>{
                      // console.log('after sent userdata',res)
                    });
                    //end connnection info pass to server
            
                  this.hubConnection.invoke('GetDataFromClient', 'abc@abc.com', data).catch(err => console.log(err));
                });
                //end signalR
              }
            
              public stopConnection() {
                this.hubConnection.stop().then(() => {
                  // console.log('stopped');
                }).catch(err => console.log(err));
              }
            
              //end signalR

}
