<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb" *ngIf="!router.url.includes('/pos-system')">
    <div class="row">
        <div class="col-6 align-self-center">
            <!-- <h4 class="page-title">{{pageInfo?.title}}</h4> -->
            <h6 class="page-title" style="font-size: 17px;"><a>{{pageInfo?.mainMenu}} </a> / <a>{{pageInfo?.title}} </a></h6>
        </div>
        
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->