import { RouteInfo } from './sidebar.metadata';

export const CASHERROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-chart-pie',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/admin/pos-system',
        title: 'POS System',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },

];