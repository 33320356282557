import { RouteInfo } from './sidebar.metadata';

export const BRANDROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-chart-pie',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/brand-dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-view-dashboard',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Loyalty',
    icon: 'mdi mdi-home',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/loyalty/',
        title: 'Loyalty',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/loyalty/loyalty-menu-group',
        title: 'Loyalty Menu Group',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/loyalty/loyalty-type',
      //   title: 'Loyalty Type',
      //   icon: 'mdi mdi-food-fork-drink',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/reports/loyalty-reports',
        title: 'Loyalty Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/customer-loyalty-report',
        title: 'Customer Loyalty Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/loyalty-points-report',
        title: 'Loyalty Points Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/loyalty-reports/loyalty-points-log-report',
        title: 'Loyalty Points Log Report',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
]
