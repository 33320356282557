import { Directive , HostListener , ElementRef , AfterContentInit , Input} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements  AfterContentInit{

  
  @Input() public appAutoFocus : boolean ;
  constructor(private elementRef : ElementRef) { }
 


  public ngAfterContentInit(): void {
    alert('hete')
      setTimeout(() => {
          this.elementRef.nativeElement.focus();
      }, 500);
  }
}
