import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { User } from 'src/app/auth/login/user';
import { DbService } from 'src/app/shared/services/db.service';
//declare var $: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  dateEnd : number = 0;
	public config: PerfectScrollbarConfigInterface = {};

  	constructor(public router: Router , public dbService : DbService) {}

	public innerWidth: number=0;
	public defaultSidebar: string='';
	public showMobileMenu = false;
	public expandLogo = false;
	public sidebartype = 'full';

  showMessage : boolean = false;

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  // checkStoreSubscription(){
  //   this.dbService.methodGet("Login/CheckStoreSubscription").subscribe(resdata =>{
  //     
  //   })
  // }


   datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}
  ngOnInit() {
    let user : User = JSON.parse(localStorage.getItem('currentUser'));

    // this.checkStoreSubscription();

    if(user.expiryDate != null){
      this.dateEnd = (this.datediff(new Date , new Date(user.expiryDate)));
    // this.dateEnd = this.dateEnd + user.extendedDays;
    if(this.dateEnd <= user.reqularWarningBefore){
      // if(this.dateEnd < 0){
      //   if(user.expiryDate != null){
      //     this.router.navigate(['/auth']);
      //     localStorage.removeItem('currentUser');
      //     localStorage.removeItem('orderCount');
      //     localStorage.clear();
      //   }

      // }
      this.showMessage = true;

    }
    else if (this.dateEnd <= user.firstWarningBefore){
      // if(this.dateEnd < 0){
      //   if(user.expiryDate != null){
      //     this.router.navigate(['/auth']);
      //     localStorage.removeItem('currentUser');
      //     localStorage.removeItem('orderCount');
      //     localStorage.clear();
      //   }

      // }
      this.showMessage = true;
    //   setTimeout(() => {
    //     this.showMessage = false ;
    // }, 5000);
    }
    }





    if (this.router.url === '/') {
      this.router.navigate(['/admin']);
    }
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }
}
