import { RouteInfo } from './sidebar.metadata';

export const OFFICEROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/home',
    title: 'Home',
    icon: 'mdi mdi-home',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Modules',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  }, 
  {
    path: '/menu',
    title: 'Menu',
    icon: 'mdi mdi-menu',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu/product',
    title: 'Product',
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/reports/store-reports/officereport',
    title: 'Office Report',
    icon: 'mdi mdi-poll-box',
    class: '',
    extralink: false,
    submenu: []
  }
];
