<div class="wrapper fadeInDown">
  <div id="formContent">

    <h2 class="active"> Sign In </h2>

    <div class="fadeIn first" style="margin: 15px;">
      <img src="assets/images/login_logo.png" id="icon" alt="User Icon" />
    </div>
    <div class="alert alert-danger" style="margin: 15px;" *ngIf="Error">
      {{message}}
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <input type="text" class="fadeIn second" formControlName="Email" placeholder="Username">
      <input type="password" class="fadeIn third" formControlName="Password" placeholder="Password">
      <input type="submit" [disabled]="!loginForm.valid" style="margin-top: 15px;" class="fadeIn fourth" value="Log In">
    </form>

    <div id="formFooter">
      <a class="underlineHover" href="#">Forgot Password?</a>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
