<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="(sidebarnavItem.class === '' || sidebarnavItem.directRoute) && !sidebarnavItem.expand ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title,sidebarnavItem)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]" style="margin-right: 5px;"></i>
                <span class="hide-menu" style="width: 100%;">{{sidebarnavItem.title}}</span>
                <i style="margin-left: 3%; text-align: right; font-size: 17px;"  *ngIf="sidebarnavItem.submenu.length > 0" class="mdi mdi-plus"></i>
            </a>

             <!-- sub menu -->
            <ul id="sidebarnav" *ngIf="sidebarnavItem.expand">
                <li class="sidebar-item" *ngFor="let item of sidebarnavItem.submenu">
                    <a class="sidebar-link waves-effect waves-dark" [routerLink]="item.class === '' ? [item.path] : null"
                    
                        [ngClass]="[item.class]" *ngIf="!item.extralink;" (click)="addExpandClass(item.title,item)"
                        [routerLinkActive]="item.submenu.length != 0 ? '' : 'active'">
                        <i [ngClass]="[item.icon]"  style="margin-left: 20px; font-size: 15px;"></i>
                        <span class="hide-menu sub-menu">{{item.title}}</span>
                    </a>
                </li>
            </ul>

        </li>
    </ul>
</nav>