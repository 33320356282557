import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { LoginserviceService } from './loginservice.service';
import { Loginuser } from './loginuser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignalRService } from 'src/app/shared/services/signal-r.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  message: string='';
  Error = false;
  constructor(private spinner: NgxSpinnerService,
              private signalR: SignalRService,
              private loginservice: LoginserviceService,
              private formbuilder: FormBuilder,
              private toastr : ToastrService,
              private router: Router) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('currentUser');
        if (token !== undefined) { // you can update this as per your key
          // DO LOGOUT FROM THIS TAB AS WELL
          window.location.reload();
          // this.router.navigate(['/']); // If you are using router
          // OR
          // window.location.href = '<home page URL>';
        }
      }
    }, false);
  }


  ngOnInit() {
    // localStorage.removeItem('currentUser');
    if(localStorage.getItem('currentUser')){
     let succ= JSON.parse(localStorage.getItem('currentUser') || '');
          if (succ.roleId == "1") {
            this.router.navigate(['/dashboard/admin-dashboard']);
          }
          else if (succ.roleId == "2") {
            this.router.navigate(['/dashboard/company-dashboard']);
          }
          else if(succ.roleId == "3"){
            this.router.navigate(['/dashboard/store-dashboard']);
          }
          else if(succ.roleId == "11"){
           this.router.navigate(['/dashboard/brand-dashboard']);
           }
           else {
            this.router.navigate(['/home']);
          }
    }

    this.setFormState();
  }


  setFormState(): void {
    this.loginForm = this.formbuilder.group({
      Email: ['', [Validators.required]],
      Password: ['', [Validators.required]]
    })
  }

  onSubmit() {
    let login = this.loginForm.value;
    this.login(login);
  }
  login(loginUser: Loginuser) {
    this.spinner.show();
    this.loginservice.loginuser(loginUser).subscribe(
      user => {
        var succ = user;
        if (succ != null) {
          if(user.expired == false){
            this.loginForm.reset();
            localStorage.setItem("currentUser", JSON.stringify(succ));
             if (succ.roleId == "1") {
              this.router.navigate(['/dashboard/admin-dashboard']);
            }
            else if (succ.roleId == "2") {
              this.router.navigate(['/dashboard/company-dashboard']);
            }
            else if(succ.roleId == "3"){
              this.router.navigate(['/dashboard/store-dashboard']);
            }
            else if(succ.roleId == "8"){
              this.router.navigate(['/admin/pos-system']);
            }
            else if(succ.roleId == "6"){
              this.router.navigate(['/waiter-pos/pos-system']);
            }
            else if(succ.roleId == "11"){
              this.router.navigate(['/dashboard/brand-dashboard']);
            }
            else {
              this.router.navigate(['/home']);
            }
            this.signalR.startConnection();
          }
          else{
            this.loginForm.reset();
            localStorage.setItem("currentUser", JSON.stringify(succ));
            this.router.navigate(['/account/account-expiry']);
          }

        } else {
          this.Error = true;
          this.message = "Email/Password Wrong";
        }
        this.spinner.hide();
      }, error => {
        this.toastr.warning("Email/Password Wrong");
        this.spinner.hide();
      }
    )
  }
}
