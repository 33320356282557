import { RouteInfo } from './sidebar.metadata';

export const WAITERROUTES: RouteInfo[] = [
  {
    path: '/waiter-pos/pos-system',
    title: 'POS System',
    icon: 'mdi mdi-chart-pie',
    class: 'nav-small',
    extralink: false,
    submenu: [
      // {
      //   path: '/waiter-pos/pos-system',
      //   title: 'POS System',
      //   icon: 'mdi mdi-account',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },

];