import { Routes } from '@angular/router';

import { AccessdeniedComponent } from './accessdenied/accessdenied.component';


export const ErrorsRoutes: Routes = [
	{
		path: '',
		children: [			
			{
				path: 'error',
				component: AccessdeniedComponent,
				data: {
					title: 'Access Denied',
				}
			},
			{
				path: 'accessdenied',
				component: AccessdeniedComponent,
				data: {
					title: 'Access Denied',
				}
            }
		]
	}
];