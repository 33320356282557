import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/admin-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-chart-pie',
    class: '',
    extralink: false,
    submenu: []
  },


  // {
  //   title: 'Dashboard',
  //   icon: 'mdi mdi-chart-pie',
  //   class: 'nav-small',
  //   path: '/dashboard/admin-dashboard',
  //   extralink: false,
  //   submenu: [
  //   ]
  // },


  {
    path: '',
    title: 'Organization',
    icon: 'mdi mdi-group',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/organization/company-group',
        title: 'Company-Group',
        icon: 'mdi mdi-home',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/organization/company',
        title: 'Company',
        icon: 'mdi mdi-home',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/organization/store',
        title: 'Store',
        icon: 'mdi mdi-food-fork-drink',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },


  // {
  //   path: '',
  //   title: 'Menu',
  //   icon: 'mdi mdi-menu',
  //   class: 'nav-small',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/admin/menu',
  //       title: 'Menu',
  //       icon: 'mdi mdi-file-document-box',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/menu/product',
  //       title: 'Product',
  //       icon: 'mdi mdi-silverware-variant',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //   ]
  // },


  {
    path: '',
    title: 'Assets',
    icon: 'mdi mdi-file-document-box',
    class: 'nav-small',
    extralink: false,
    submenu: [
      // {
      //   path: '/admin/table',
      //   title: 'Table',
      //   icon: 'mdi mdi-sofa',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/assets/office',
        title: 'Office',
        icon: 'mdi mdi-key-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/assets/employee',
        title: 'Employee',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  // {
  //   path: '',
  //   title: 'Inventory',
  //   icon: 'mdi mdi-file-document-box',
  //   class: 'nav-small',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/admin/inventory/brand',
  //       title: 'Brand',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },

  //   ]
  // },



  {
    path: '',
    title: 'Settings',
    icon: 'mdi mdi-settings',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/settings/subscription/plan',
        title: 'Plan',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/settings/subscription/package',
        title: 'Package',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/settings/subscription/package-builder',
        title: 'Package-Builder',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/settings/subscription',
        title: 'Subscription',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },

      // {
      //   path: '/admin/extend-subscription',
      //   title: 'Extend Subscription',
      //   icon: 'mdi mdi-package',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },

      {
        path: '/settings/subscription/subscription-settings',
        title: 'Subscription-Settings',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/settings/subscription/one-time-charges',
        title: 'One-time Charges',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/offers-mapping/offers-mapping',
        title: 'Offers Mapping',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/settings/admin-settings/location',
        title: 'Location',
        icon: 'mdi mdi-google-maps',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/admin-settings/store-category',
        title: 'Store Category',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/settings/admin-settings/payment-mapping',
        title: 'Payment Mapping',
        icon: 'mdi mdi-label',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },



  {
    path: '/admin/feedback',
    title: 'Feedback',
    icon: 'mdi mdi-note-text',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/loyalty',
    title: 'Loyalty',
    icon: 'mdi mdi-note-text',
    class: '',
    extralink: false,
    submenu: []
  },

  {
    path: '',
    title: 'Reports',
    icon: 'mdi mdi-file-document-box',
    class: 'nav-small',
    extralink: false,
    submenu: [
      {
        path: '/reports/admin-reports/edine-consolidated-report',
        title: 'Consolidated Report',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/admin-reports/log-reports',
        title: 'Log Reports',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/reports/admin-reports/subscription-reports',
        title: 'Subscription Reports',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/send-sms/send-sms-log',
        title: 'SMS Log Report',
        icon: 'mdi mdi-book',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  // {
  //   path: '',
  //   title: 'Inventory',
  //   icon: 'mdi mdi-file-document-box',
  //   class: 'nav-small',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/admin/inventory/brand',
  //       title: 'Brand',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/admin/inventory/category',
  //       title: 'Category',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/admin/inventory/subcategory',
  //       title: 'Category',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/admin/inventory/supplier',
  //       title: 'Supplier',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/admin/inventory/product',
  //       title: 'Product',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/admin/inventory/purchase',
  //       title: 'Purchase',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/admin/inventory/stock-list',
  //       title: 'Stock List',
  //       icon: 'mdi mdi-sofa',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },

  //   ]
  // },
];
