import { RouteInfo } from './sidebar.metadata';

export const STAFFROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    directRoute : false,
    submenu: []

  },
  {
    path: '/home',
    title: 'Home',
    icon: 'mdi mdi-home',
    class: '',
    extralink: false,
    directRoute : false,
    submenu: []
  },
  {
    path: '/admin/table-booking',
    title: 'Table Booking',
    icon: 'mdi mdi-table',
    class: '',
    extralink: false,
    directRoute : false,
    submenu: []
  },
  {
    path: '/admin/booking-info',
    title: 'Booking Information',
    icon: 'mdi mdi-book',
    class: '',
    extralink: false,
    directRoute : false,
    submenu: []
  },



  {
    path: '',
    title: 'Menu',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    directRoute : false,
    submenu: []
  },
  {
    path: '/menu/menu-groups',
    title: 'Category Groups',
    icon: 'mdi mdi-file-document-box',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu',
    title: 'Cateogory',
    icon: 'mdi mdi-file-document-box',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu/products',
    title: 'Products',
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu/add-ons',
    title: 'Addon Products',
    params : {url : true},
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu/combo-menu-categories',
    title: 'Combo Categories',
    icon: 'mdi mdi-file-document-box',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu/combo-products',
    title: 'Combo Products',
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/menu/products',
    title: 'Products',
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/catering/catering-category',
    title: 'Catering Category',
    params : {url : true},
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/catering/catering-products',
    title: 'Catering Products',
    params : {url : true},
    icon: 'mdi mdi-silverware-variant',
    class: '',
    extralink: false,
    submenu: []
  },

  {
    path: '',
    title: 'Assets',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    directRoute : false,
    submenu: []
  },
  {
    path: '/assets/table',
    title: 'Table',
    icon: 'mdi mdi-table',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Settings',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    directRoute : false,
    submenu: []
  },
  {
    path: '/settings/reservation-settings',
    title: 'Reservation Settings',
    icon: 'mdi mdi-label',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings/store-settings',
    title: 'Store Settings',
    icon: 'mdi mdi-store',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings/delivery-settings',
    title: 'Delivery Settings',
    icon: 'mdi mdi-label',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings/takeaway-settings',
    title: 'Takeaway Settings',
    icon: 'mdi mdi-label',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings/pos-settings',
    title: 'POS Settings',
    icon: 'mdi mdi-label',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings/printer',
    title: 'Printer',
    icon: 'mdi mdi-label',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings/printer/printer-type',
    title: 'Printer Type',
    icon: 'mdi mdi-label',
    class: '',
    extralink: false,
    submenu: []
  },

];
