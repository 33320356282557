import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Loginuser} from './loginuser';
import {User} from './user';
import { Observable } from 'rxjs';
import {GlobalService} from '../../global.service';

@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {

  constructor(private http:HttpClient, private global: GlobalService) { }

  loginuser(loginUser: Loginuser):Observable<User>{
    return this.http.post<User>(this.global.getApiURL()+'api/login', loginUser)
  }

  
  userConnection(id,zone){
    return this.http.post(this.global.getApiURL() +'api/Users/UpdateUserConnection/'+id,zone)
  }
  
}
