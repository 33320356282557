import { Component } from '@angular/core';
import { SignalRService } from './shared/services/signal-r.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loadingRouteConfig: boolean = false;

  constructor( private signalR : SignalRService,
    private spinner : NgxSpinnerService,
    private router : Router,
    ) { }


  ngOnInit() {



    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
    this.spinner.show();

      } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
          this.spinner.hide();
      }
  });



    //call signalR
    this.signalR.startConnection();
    //  console.log('start @ app.com')
  }
}
